
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import moment from "moment";
import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";
// import Dropdown4 from "@/components/dropdown/Dropdown4.vue";
// import contacts from "@/core/data/contacts";
import * as Yup from "yup";
import MessageIn from "@/components/messenger-parts/MessageIn.vue";
import MessageOut from "@/components/messenger-parts/MessageOut.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Treeselect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRoute, useRouter } from "vue-router";
import { io } from "socket.io-client";
import useEmitter from '@/composables/useEmitter'

interface KTMessage {
  type: string;
  name?: string;
  image: string;
  time: string;
  text: string;
  _id: string;
}

export default defineComponent({
  name: "private-chat",
  components: {
    MessageIn,
    MessageOut,
    // Dropdown4,
    ErrorMessage,
    Field,
    Form,
    Treeselect,
    VPagination,
  },
  setup() {
    var tagData = ref("");
    const messagesRef = ref<null | HTMLElement>(null);
    const value = ref<any>(0);
    const createValue = ref(null);
    const options = ref<any>([]);
    const createOptions = ref<any>([]);
    const messagesInRef = ref<null | HTMLElement>(null);
    const messagesOutRef = ref<null | HTMLElement>(null);
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const messageData = ref<any>("");
    const clickChatBox = ref<any>(null);
    const noteListing = ref<any>([]);
    const messages = ref<Array<KTMessage>>([]);
    const showTable = ref(false);
    const total_pages = ref(1);
    const sendMessage = Yup.object().shape({
      message: Yup.string().required().label("Message"),
    });
    const addNote = Yup.object().shape({
      message: Yup.string().required().label("Enter Note"),
    });
    const movefolder = Yup.object().shape({
      folderName: Yup.string().required().label("Folder Name"),
    });
    const moveFolderMultiple = Yup.object().shape({
      folderId: Yup.string().required().label("Folder Name"),
    });
    const movefolderList = ref([]);
    const foldercreateValue = ref("");
    const mainFolderVal = ref("");
    const registration = Yup.object().shape({
      type: Yup.string().required().label("select type"),
    });
    const createfolder = Yup.object().shape({
      name: Yup.string().required().label("Enter folder name"),
    });
    const messageInterval = ref<any>(false);
    const inboxInterval = ref<any>(false);
    const activeMessage = ref<any>(false);
    const submitButtonModel = ref<HTMLButtonElement | null>(null);
    const submitButtonMessageSend = ref<HTMLButtonElement | null>(null);
    const submitButtonCreatefolder = ref<HTMLButtonElement | null>(null);
    const submitButtonAddNote = ref<HTMLButtonElement | null>(null);
    const submitButtonMultiplnumbermoved = ref<HTMLButtonElement | null>(null);
    const loading = ref<boolean>(false);
    const newMessageText = ref("");
    const search = ref("");
    const submitButton = ref<HTMLButtonElement | null>(null);
    const checkAll = ref<any>(false);
    const limit = ref(50);
    const emitter = useEmitter()
    const addNewMessage = () => {
      if (!newMessageText.value) {
        return;
      }

      if (messagesRef.value) {
        messagesRef.value.scrollTop = messagesRef.value.scrollHeight;
      }
      newMessageText.value = "";

      if (messagesRef.value) {
        messagesRef.value.scrollTop = messagesRef.value.scrollHeight;
      }
    };
    const numbersGet = ref("");
    const inboxName = ref('General Inbox');
    const contactData = ref(null);
    const checkednumberArr: any = ref([]);
    const getFolder = () => {
      var request = {
        url: `folder-get`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          // console.log(data)
          options.value = data;
          createOptions.value = data;
        }
        folder();
      });
    };

    const folderGetValue = (value) => {
      console.log('value');
      console.log(value);
      foldercreateValue.value = value.id;
    };

    const mainInputfolder = (data) => {
      // alert(JSON.stringify(data));
      // console.log(data);
      if (data.id == "0") {
        mainFolderVal.value = "";
        inboxName.value = 'General Inbox';
      } else {
        mainFolderVal.value = data.id;
        
        inboxName.value = data.label;
      }
      getNumberData(1);
    };

    const getNoteData = () => {
      var request = {
        url: `/note/byNumber/${activeMessage.value.number}`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          // console.log("data note list", data.data);
          noteListing.value = data.data;
        }
      });
    };

    const getNumberofChat = (data) => {
      numbersGet.value = data;
    };

    const Blocknumber = (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You are trying to Block this Number!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          var request = {
            url: "/block-number",
            data: {
              number: id,
            },
          };
          store
            .dispatch(Actions.POST, request)
            .then((data) => {
              if (data) {
                Swal.fire("Success", "Number Block successfully!", "success");
                //getNumberData();
                location.reload();
              }
              loading.value = false;
            })
            .catch(() => {
              const [error] = Object.keys(store.getters.getErrors);
              Swal.fire({
                text: store.getters.getErrors[error],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Try again!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        }
      });
    };

    const clickCheckbox = (e, item) => {
      if (e.target.checked) {
        checkednumberArr.value.push(item._id);
      } else {
        var index = checkednumberArr.value.indexOf(item._id);
        if (index > -1) {
          checkednumberArr.value.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
      // console.log('checkednumberArr',checkednumberArr.value);
    };
    const chatOption = ref<any>(null);
    const showDataSelect = ref(false);
    const socket = ref<any>(null);
    onMounted(() => {
      showChatMessage.value = true;
      showDataSelect.value = true;
      getTagData();
      getNumberData(1);
      getFolder();

      console.log(route.params.id);
      if(route.params.id){
        getNumberData2(route.params.id);
        if(route.params.folder){
          showDataSelect.value = false;
          console.log(route.params.folder);
          
          value.value = [ route.params.folder ]
          
          setTimeout(() => {
            showDataSelect.value = true;
          }, 1000);
          setTimeout(() => {
            for(let folder of options.value){
              console.log(folder);
              if(folder.id == route.params.folder){
                mainInputfolder(folder);
              }
            }
          },2000);
          //options.value
          // getNumberData(1);
        }
        // var strData:any = route.params.id;
        // var numberData = JSON.parse(atob(strData));
        // numberClick(numberData);
      }
      
      // getNoteData();
      // folder();
      // getMessageChat();
      //messageInterval.value = setInterval(function () {
      //getNumberData();
      //}, 10000);
      setCurrentPageBreadcrumbs("General Inbox", []);
      socket.value = io(`${process.env.VUE_APP_SOKET_URL}`, {
        transports: ["websocket"],
      });
      
      socket.value.on("chat_message", channelListener);
    });
    const channelListener = (msg) =>{
      const userData = localStorage.getItem("userdata");
      if (userData) {
          var objUser = JSON.parse(userData);
          if (objUser._id == msg.user_id) {
            
            getNumberData(1);
            if (clickChatBox.value && clickChatBox.value.id == msg.number) {
              getNumberData2(msg.number);
              getAllMessages();
            }
          }
        }
    };
    const showChatMessage = ref(false);
    onUnmounted(() => {
      socket.value.off("chat_message", channelListener);
      
    });

    const mbStrimWidth = (data) => {
      console.log("sdf", data);
      var str = data;
      if (str.length > 10) str = str.substring(0, 10)+'..';
      //  console.log('cut string',str);
      return str;
    };
    const getNumberData2 = (number) =>{
      var request = {
              url: `message-get-one/`+number,
              data: {
                number: number,
              },
            };
      store.dispatch(Actions.POST, request).then((response) => {
         console.log('response');
        console.log(response);
        var numberData = response.data
        numberData._id = response.data.number
        numberClick(numberData);
      });
    }
    const OptoutNumber = () => {
      if (checkednumberArr.value.length <= 0) {
        Swal.fire({
          text: "Please select any number!",
          icon: "warning",
          confirmButtonText: "Okay",
        });
      } else {
        Swal.fire({
          title: "Are you sure?",
          text: "You are trying to block these selected Conversion!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Okay",
        }).then((result) => {
          if (result.isConfirmed) {
            var request = {
              url: `multiple-block-number`,
              data: {
                numbers: checkednumberArr.value,
              },
            };
            store.dispatch(Actions.POST, request).then((data) => {
              if (data) {
                Swal.fire({
                  text: "All Selected Number has been blocked!",
                  icon: "success",
                  confirmButtonText: "Okay",
                }).then((result) => {
                  if (result.isConfirmed) {
                    getNumberData(1);
                    // location.reload();
                  }
                });
              }
            });
          }
        });
      }
    };

    const deleteMultipleNumber = () => {
      if (checkednumberArr.value.length <= 0) {
        Swal.fire({
          text: "Please select any number!",
          icon: "warning",
          confirmButtonText: "Okay",
        });
      } else {
        Swal.fire({
          title: "Are you sure?",
          text: "You are trying to delete these selected Conversion!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Okay",
        }).then((result) => {
          if (result.isConfirmed) {
            var request = {
              url: `delete-selected-number`,
              data: {
                numbers: checkednumberArr.value,
              },
            };
            store.dispatch(Actions.POST, request).then((data) => {
              if (data) {
                Swal.fire({
                  text: "All Selected Number has been deleted!",
                  icon: "success",
                  confirmButtonText: "Okay",
                }).then((result) => {
                  if (result.isConfirmed) {
                    getNumberData(1);
                    // location.reload();
                  } else {
                    Swal.fire(
                      "Cancelled",
                      "Selected Number not deleted!",
                      "error"
                    );
                  }
                });
              }
            });
          }
        });
      }
    };

    const getTagData = () => {
      var request = {
        url: "/tag",
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          // console.log('data',data.data[0].tag_name);
          tagData.value = data.data;
        }
      });
    };
    const folder = () => {
      var request = {
        url: "folder/all",
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          movefolderList.value = data.data;
          // console.log('movefolderList',movefolderList.value);
        }
      });
    };

    const getNumberData = (page) => {
      showTable.value = false;
      var request = {
        url: `/message_all?skip=${page}&limit=${limit.value}`,
        data: {
          type: "user",
          tag: "",
          name: mainFolderVal.value,
          message: search.value,
        },
      };
      store.dispatch(Actions.POST, request).then((data) => {
        if (data) {
          // console.log('data number',data.data);
          messages.value = data.data;
          total_pages.value = Math.ceil(data.total / limit.value);
          console.log(total_pages.value);
          showTable.value = true;
          //  console.log('message value',messages);
          // if (activeMessage.value) {
          //   getAllMessages();
          // }
        }
      });
    };

    const getAllMessages = () => {
      //inboxInterval.
      var item = activeMessage.value;
      var name = "";
      if (item.contact) {
        var group_id = item.contact.group_id;
        var data = item.contact.first_name + item.contact.last_name;
        if (data) {
          name = data;
        }
      } else {
        name = "Unknown";
      }
      clickChatBox.value = {
        id: item._id,
        twilio_number: item.twilio_number,
        type: item.type,
        name: name,
        group_id: group_id,
      };
      var request = {
        url: "/get-messages",
        data: {
          number: item._id,
          twilio_number: item.twilio_number,
          type: item.type,
        },
      };
      store.dispatch(Actions.POST, request).then((data) => {
        if (data) {
          // console.log('message data for click on number',data.data);
          messageData.value = data.data;
          //  console.log('message value',messages);
          setTimeout(() => {
            var objDiv = document.getElementById("your_div");
            if (objDiv) {
              objDiv.scrollTop = objDiv.scrollHeight;
            }
          }, 500);
        }
      });
    };

    const numberClick = (item) => {
      isActiveNumber.value = item._id
      showChatData.value = false
      if (inboxInterval.value) {
        clearInterval(inboxInterval.value);
      }

      const boxes:any = document.querySelectorAll(`.item_${item._id}`);

      for (const box of boxes) {
        box.classList.remove(
          'text-danger'
        );
      }
      activeMessage.value = item;
      console.log("id on click event", item);
      getNoteData();
      // var data =;
      var name = "";
      if (item.contact) {
        var group_id = item.contact.group_id;
        var data = item.contact.first_name + item.contact.last_name;
        if (data) {
          name = data;
        }
      } else {
        name = "Unknown";
      }
      clickChatBox.value = {
        id: item._id,
        twilio_number: item.twilio_number,
        type: item.type,
        name: name,
        group_id: group_id,
      };
      var request = {
        url: "/get-messages",
        data: {
          number: item._id,
          twilio_number: item.twilio_number,
          type: item.type,
        },
      };
      store.dispatch(Actions.POST, request).then((data) => {
        if (data) {
          // console.log('message data for click on number',data.data);
          messageData.value = data.data;
          //  console.log('message value',messages);
          
          setTimeout(() => {
            var objDiv = document.getElementById("your_div");
            if (objDiv) {
              objDiv.scrollTop = objDiv.scrollHeight;
            }
            emitter.emit("toggle-sidebar2", 'test');
            showChatData.value = true
          }, 500);
        }
      });
    };

    const getMessgeOfconversion = (id) => {
      var request = {
        url: "/get-messages",
        data: {
          number: id,
          twilio_number: clickChatBox.value.twilio_number,
          type: clickChatBox.value.type,
        },
      };
      store.dispatch(Actions.POST, request).then((data) => {
        if (data) {
          // console.log('message data for click on number',data.data);
          messageData.value = data.data;
          //  console.log('message value',messages);
        }
      });
    };

    const onSubmitSendMessage = (values, { resetForm }) => {
      // eslint-disable-next-line
      submitButton.value!.disabled = true;
      loading.value = true;
      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      if (values.message.trim() == "") {
        Swal.fire({
          text: "Please enter message!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        loading.value = false;
      } else {
        loading.value = true;
        var request = {
          url: "send-inboxmessage",
          data: {
            number: clickChatBox.value.id,
            twilio_number: clickChatBox.value.twilio_number,
            message: values.message,
          },
        };
        store
          .dispatch(Actions.POST, request)
          .then((data) => {
            loading.value = false;
            submitButton.value?.removeAttribute("data-kt-indicator");
            submitButton.value!.disabled = false;
            if (data) {
              resetForm();
              // Swal.fire({
              //   text: "Messsage Send Successfully!",
              //   icon: "success",
              //   buttonsStyling: false,
              //   confirmButtonText: "Ok, got it!",
              //   customClass: {
              //     confirmButton: "btn fw-bold btn-light-primary",
              //   },
              // }).then(function () {
              //   submitButton.value?.removeAttribute("data-kt-indicator");
              //   submitButton.value!.disabled = false;
              //   getAllMessages();
              // });
              
              getAllMessages();

              // Swal.fire("Success", " ", "success");
              // router.push({ name: "user-autoresponder-keyword" });
            }
          })
          .catch(() => {
            loading.value = false;
            const [error] = Object.keys(store.getters.getErrors);
            Swal.fire({
              text: store.getters.getErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
            submitButton.value?.removeAttribute("data-kt-indicator");
            submitButton.value!.disabled = false;
            loading.value = false;
          });
      }
    };

    const onSubmitRegister = (values, { resetForm }) => {
      // Clear existing errors

      // eslint-disable-next-line
      submitButtonModel.value!.disabled = true;

      // Activate indicator
      submitButtonModel.value?.setAttribute("data-kt-indicator", "on");
      loading.value = true;
      var request = {
        url: "move-tag",
        data: {
          tag_id: values.type,
          number: clickChatBox.value.id,
          group_id: clickChatBox.value.group_id,
        },
      };
      store
        .dispatch(Actions.POST, request)
        .then((data) => {
          if (data) {
            Swal.fire("Success", "Tag Move successfully!", "success");
          }
          loading.value = false;
          resetForm();
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getErrors);
          Swal.fire({
            text: store.getters.getErrors[error],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      submitButtonModel.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
        submitButtonModel.value!.disabled = false;
      // Swal.fire({
      //   text: "User added successfully!",
      //   icon: "success",
      //   buttonsStyling: false,
      //   confirmButtonText: "Ok, got it!",
      //   customClass: {
      //     confirmButton: "btn fw-bold btn-light-primary",
      //   },
      // }).then(function () {
      //   router.push({ name: "admin-user" });
      // });
    };

    const onSubmitCreateFolder = (values, { resetForm }) => {
      const userData = localStorage.getItem("userdata");
      var allData = JSON.parse("" + userData + "");
      submitButtonCreatefolder.value!.disabled = true;
      submitButtonCreatefolder.value?.setAttribute("data-kt-indicator", "on");
      console.log(values);
      console.log(foldercreateValue.value);
      //if (foldercreateValue.value) {
      loading.value = true;
      var request = {
        url: "folders",
        data: {
          user_id: allData._id,
          name: values.name,
          sub_folder_id: foldercreateValue.value,
        },
      };
      store
        .dispatch(Actions.POST, request)
        .then((data) => {
          if (data) {
            Swal.fire("Success", "Folder Create successfully!", "success");
            getFolder();
          }
          resetForm();
          //folder_close
          document.getElementById("folder_close")?.click();
          loading.value = false;
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getErrors);
          Swal.fire({
            text: store.getters.getErrors[error],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
      // } else {
      //   Swal.fire({
      //     text: "Something is wrong!",
      //     icon: "error",
      //     buttonsStyling: false,
      //     confirmButtonText: "Try again!",
      //     customClass: {
      //       confirmButton: "btn fw-bold btn-light-danger",
      //     },
      //   });
      // }
      submitButtonCreatefolder.value?.removeAttribute("data-kt-indicator");
      submitButtonCreatefolder.value!.disabled = false;
    };

    const onSubmitAddNote = (values, { resetForm }) => {
      submitButtonAddNote.value!.disabled = true;
      submitButtonAddNote.value?.setAttribute("data-kt-indicator", "on");
      if (values.message.trim() == "") {
        Swal.fire({
          text: "Please fill all fields!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else {
        loading.value = true;
        var request = {
          url: "notes",
          data: {
            mobileno: numbersGet.value,
            message: values.message,
          },
        };
        store
          .dispatch(Actions.POST, request)
          .then((data) => {
            if (data) {
              resetForm();
              Swal.fire("Success", "Note add successfully!", "success");
              getNoteData();
            }
            loading.value = false;
          })
          .catch(() => {
            const [error] = Object.keys(store.getters.getErrors);
            Swal.fire({
              text: store.getters.getErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      }
      submitButtonAddNote.value?.removeAttribute("data-kt-indicator");
      submitButtonAddNote.value!.disabled = false;
    };

    const onSubmitMovefolder = (values, { resetForm }) => {
      submitButtonAddNote.value!.disabled = true;
      submitButtonAddNote.value?.setAttribute("data-kt-indicator", "on");
      //  console.log('movefolder',values);
      //  console.log('dffgf',numbersGet.value);
      loading.value = true;
      var request = {
        url: "move-folder",
        data: {
          folder_id: values.folderName,
          number: numbersGet.value,
        },
      };
      store
        .dispatch(Actions.POST, request)
        .then((data) => {
          if (data) {
            Swal.fire("Success", "Folder Moved successfully!", "success");
          }
          loading.value = false;
          document.getElementById("Move_to_another_folder")?.click();
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getErrors);
          Swal.fire({
            text: store.getters.getErrors[error],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
      // }
      submitButtonAddNote.value?.removeAttribute("data-kt-indicator");
      submitButtonAddNote.value!.disabled = false;
    };

    const onSubmitMultipleMove = (values, { resetForm }) => {
      submitButtonMultiplnumbermoved.value!.disabled = true;
      submitButtonMultiplnumbermoved.value?.setAttribute(
        "data-kt-indicator",
        "on"
      );
      //  console.log('dffgf',numbersGet.value);
      if (checkednumberArr.value.length <= 0) {
        Swal.fire({
          text: "Please select any number!",
          icon: "warning",
          confirmButtonText: "Okay",
        });
      } else {
        loading.value = true;
        var request = {
          url: "moveSelected-number",
          data: {
            folder_id: values.folderId,
            number: checkednumberArr.value,
          },
        };
        store
          .dispatch(Actions.POST, request)
          .then((data) => {
            if (data) {
              Swal.fire({
                text: "Number Moved in folder successfully!",
                icon: "success",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes",
              }).then((result) => {
                if (result.isConfirmed) {
                  //location.reload();
                  getNumberData(1);
                  document.getElementById("multiple_move_folder")?.click();
                }
              }); // getNumberData();
            }
            loading.value = false;
          })
          .catch(() => {
            const [error] = Object.keys(store.getters.getErrors);
            Swal.fire({
              text: store.getters.getErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      }
      // }
      submitButtonMultiplnumbermoved.value?.removeAttribute(
        "data-kt-indicator"
      );
      submitButtonMultiplnumbermoved.value!.disabled = false;
    };

    const deleteFolder = (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You are trying to Delete This Folder!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          if (id) {
            if (id == 0) {
              Swal.fire({
                text: "Please Select a Folder :)",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Try again!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            } else {
              var request = {
                url: `folder/${id}`,
              };
              store.dispatch(Actions.DELETE, request).then((data) => {
                Swal.fire("Deleted!", "Folder has been deleted.", "success");
                getFolder();
                value.value = null;
                // location.reload();
              });
            }
          } else {
            Swal.fire({
              text: "Please Select a Folder :)",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          }
        }
      });
    };

    const deleteChat = (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You are trying to delete the conversation!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var request = {
            url: `message-delete`,
            data: {
              number: id,
            },
          };
          store.dispatch(Actions.POST, request).then((data) => {
            if (data) {
              Swal.fire(
                "Deleted!",
                "Conversation has been deleted successfully.",
                "success"
              );
              getMessgeOfconversion(id);
              getNumberData(1);
            }
            // location.reload();
          });
        }
      });
    };

    const searchItems = () => {
      getNumberData(1);
    };

    const getInfo = (id) => {
      var request = {
        url: "/contact-info",
        data: {
          number: id,
        },
      };
      store.dispatch(Actions.POST, request).then((data) => {
        if (data) {
          contactData.value = data.data;
        }
      });
    };

    const dateTime = (value) => {
      return moment(value).format("YYYY-MM-DD H:m:s");
    };

    const handleCheckbox = (event) => {
      if (checkAll.value) {
        var arrSelected: any = [];
        for (let message of messages.value) {
          arrSelected.push(message._id);
        }
        checkednumberArr.value = arrSelected;
      } else {
        checkednumberArr.value = [];
      }
    };

    const updateHandler = (value) => {
      showTable.value = false;
      getNumberData(value);
    };

    // const getItem = (page) => {
    //   var id = route.params.id;
    //   var request = {
    //     url: `/groupwise-conatct/${id}?skip=${page}&limit=${limit.value}`,
    //   };
    //   store.dispatch(Actions.GET, request).then((data) => {
    //     if (data) {
    //       messages.value = data.data;
    //       // initCustomers.value.splice(
    //       //     0,
    //       //     tableData.value.length,
    //       //     ...tableData.value
    //       // );
    //       total_pages.value = Math.ceil(data.total / limit.value);
    //       console.log(total_pages.value);
    //       showTable.value = true;
    //       // console.log('datata',tableData.value);
    //     }
    //   });
    // };
    const page = ref(1);
    const showChatData = ref(false);
    const isActiveNumber = ref('0');
    return {
      messages,
      messagesRef,
      newMessageText,
      addNewMessage,
      messagesInRef,
      messagesOutRef,
      getInfo,
      getTagData,
      tagData,
      value,
      options,
      getNumberData,

      mbStrimWidth,
      numberClick,
      clickChatBox,
      messageData,
      submitButtonModel,
      dateTime,
      submitButtonMessageSend,
      onSubmitSendMessage,
      submitButtonCreatefolder,
      sendMessage,
      onSubmitRegister,
      registration,
      getFolder,
      createValue,
      createOptions,
      createfolder,
      onSubmitCreateFolder,
      folderGetValue,
      foldercreateValue,
      mainFolderVal,
      mainInputfolder,
      deleteFolder,
      getNoteData,
      noteListing,
      folder,
      movefolderList,
      getNumberofChat,
      numbersGet,
      Blocknumber,
      onSubmitAddNote,
      submitButtonAddNote,
      addNote,
      onSubmitMovefolder,
      movefolder,
      contactData,
      deleteChat,
      getMessgeOfconversion,
      clickCheckbox,
      checkednumberArr,
      OptoutNumber,
      deleteMultipleNumber,
      onSubmitMultipleMove,
      submitButtonMultiplnumbermoved,
      moveFolderMultiple,
      getAllMessages,
      search,
      searchItems,
      submitButton,
      checkAll,
      handleCheckbox,
      updateHandler,
      total_pages,
      limit,
      showTable,
      inboxName,
      page,
      showDataSelect,
      showChatData,
      isActiveNumber
    };
  },
});
